import React from 'react'

function Input(props) {

  const style = { 
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 12,
    paddingRight: 12,
    border: '3px solid',
    borderColor: '#FFFFFF',
    backgroundColor: '#FFFFFF',
    color: '#00473e',
    width: '100%',
    boxSizing:"border-box",
   }

  return (
    <input placeholder={props.placeholder} style={style} value={props.value} onChange={props.onChange} />
  )
}

export default Input
