import React, { useState } from 'react'
import Input from './core/Input'
import TextArea from './core/TextArea'
import Submit from './core/Submit'
import './App.css';

function App() {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  return (
    <div>
      <header>
        <h1 style={{ color: "#FFA362" }}>FELLASOFT</h1>
        {/* <div class='links'>
          <a href="#about">About</a>
          <a href="#products">Products</a>
          <a href="#contact">Contact</a>
        </div> */}

      </header>
      <div class="post-bar"></div>
      {/* <section id="about">
        <summary>
          <h1>About</h1>
        </summary>
      </section>
      <section id="products">
        <summary>
          <h1>Products</h1>
        </summary>
      </section> */}
      <section id="hero">
        <summary>
        <h1>MOVING AT THE SPEED OF BUSINESS</h1>
        <h2>KEEP UP, IBM</h2>
        </summary>
      </section>
      <div class="post-hero"></div>
      {/* <div style={{position: "relative", width: "100vw", height: "100px", backgroundColor: "#FFFFFF"}}>
        <div class="test2"></div>
      </div> */}
      <section id="about">
        <summary>
          <h1>WHO ARE WE?</h1>
          <p>We are a consortium of like minded people looking to buy out the BBC. We don't want to see Britain lose the BBC.</p>
          <p>We believe that the BBC is sleepwalking itself to destruction and the BBC will be lost to Britain and we do not thik that is right.</p>
          <p>We have run some models of possible present and future values, although we have absolutely no idea what price we would offer.</p>
          <p>Watch this space</p>
        </summary>
      </section>
      <div class="post-about"></div>
      <section id="contact">
        <summary>
          <h1>CONTACT</h1>
          <div>
          <div style={{ paddingBottom: 10 }}>
              <Input placeholder='your name' value={name} onChange={e => setName(e.target.value)} />
            </div>
            <div style={{ paddingBottom: 10 }}>
              <Input placeholder='your email' value={email} onChange={e => setEmail(e.target.value)} />
            </div>
            <div style={{ paddingBottom: 10 }}>
              <TextArea placeholder='message' value={message} onChange={e => setMessage(e.target.value)} />
            </div>
            <div style={{ textAlign: 'right' }}>
              <Submit placeholder='message' value='Send' onClick={e => sendEmail(name, email, message)} />
            </div>
          </div>
        </summary>
      </section>
      <div class="post-contact"></div>
    </div>
  );
}

function sendEmail(name, email, message) {
  console.log("egwegw")
  window.emailjs.send(
    'zoho', 'template_K8t73voV',
    { message_html: message, from_name: name, reply_to: email }
  ).then(res => {
    console.log('Email successfully sent!')
  })
    .catch(err => console.error('Oh well, you failed. Here some thoughts on the error that occured:', err))
}

export default App;
