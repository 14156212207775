import React from 'react'

function Submit(props) {

  const style = { 
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 12,
    paddingRight: 12,
    border: '3px solid',
    borderColor: '#FFFFFF',
    backgroundColor: '#FFFFFF',
    color: '#00473e',
   }

  return (
    <button type="button" style={style} onClick={props.onClick}>{props.value}</button>
  )
}

export default Submit
